import { Badge, Tooltip } from '@any-ui-react/core';
import classNames from 'classnames';

import { NumberUtils } from '~anyx/shared/utils';

const COLOR_MAP = {
  primary: '#3892E5',
  secondary: '#5BA3E6',
  tertiary: '#7DB3E6',
  quaternary: '#AFD3F5',
} as const;

export type ReviewTopic = {
  name: string;
  percentage: number;
  description: string;
  reviewIds: string[];
};

interface ReviewTopicChartProps {
  data: readonly {
    percentage: string;
    reviewIds: ReadonlyArray<string>;
    topic: string;
    topicSummary: string;
  }[];
  selectedTopic?: string;
  onToggleTopic?: (name: string) => void;
  className?: string;
}

export const ReviewTopicChart = ({
  data,
  selectedTopic,
  onToggleTopic,
  className,
}: ReviewTopicChartProps) => {
  const sortedTopics: ReviewTopic[] = [...data]
    .sort((a, b) => NumberUtils.toNumber(b.percentage) - NumberUtils.toNumber(a.percentage))
    .map((topic) => ({
      name: topic.topic,
      percentage: NumberUtils.toNumber(topic.percentage),
      description: topic.topicSummary,
      reviewIds: [...topic.reviewIds],
    }));

  const numOfColumns = 3;
  const targetColumnPercentage = 100 / numOfColumns;

  const distributeTopics = (): { name: string; children: ReviewTopic[] }[] => {
    const columns: { name: string; children: ReviewTopic[] }[] = [];
    const remainingTopics = [...sortedTopics];
    const firstTopic = remainingTopics[0];

    if (firstTopic && firstTopic.percentage > targetColumnPercentage) {
      columns.push({ name: 'column1', children: [firstTopic] });
      remainingTopics.shift();
    } else {
      const firstColumnTopics: ReviewTopic[] = [];
      let columnPercentage = 0;

      while (remainingTopics.length > 0 && columnPercentage <= targetColumnPercentage) {
        const nextTopic = remainingTopics[0];
        if (
          nextTopic === undefined ||
          columnPercentage + nextTopic.percentage > targetColumnPercentage
        ) {
          break;
        }
        firstColumnTopics.push(nextTopic);
        columnPercentage += nextTopic.percentage;
        remainingTopics.shift();
      }

      columns.push({ name: 'column1', children: firstColumnTopics });
    }

    const secondColumnTopics: ReviewTopic[] = [];
    let secondColumnPercentage = 0;

    while (remainingTopics.length > 0 && secondColumnPercentage <= targetColumnPercentage) {
      const nextTopic = remainingTopics[0];
      if (
        nextTopic === undefined ||
        secondColumnPercentage + nextTopic.percentage > targetColumnPercentage
      ) {
        break;
      }
      secondColumnTopics.push(nextTopic);
      secondColumnPercentage += nextTopic.percentage;
      remainingTopics.shift();
    }

    columns.push({ name: 'column2', children: secondColumnTopics });
    columns.push({ name: 'column3', children: remainingTopics });

    return columns;
  };
  const topicTreemap = distributeTopics();

  return (
    <div className={classNames('overflow-hidden rounded-lg', className)}>
      <div className="grid min-h-48 grid-cols-3 gap-1">
        {topicTreemap.map((column) => (
          <TopicColumn
            key={column.name}
            column={column}
            sortedTopics={sortedTopics}
            selectedTopic={selectedTopic}
            onToggleTopic={onToggleTopic}
          />
        ))}
      </div>
    </div>
  );
};

const TopicItem = ({
  item,
  totalSize,
  index,
  selectedTopic,
  onToggleTopic,
}: {
  item: ReviewTopic;
  totalSize: number;
  index: number;
  selectedTopic?: string;
  onToggleTopic?: (name: string) => void;
}) => {
  const getTopicColor = (index: number) => {
    if (index === 0) return COLOR_MAP.primary;
    if (index === 1) return COLOR_MAP.secondary;
    if (index <= 3) return COLOR_MAP.tertiary;
    return COLOR_MAP.quaternary;
  };

  const enabledToggleTopic = typeof onToggleTopic === 'function';
  const isSelectedTopic = selectedTopic === item.name;

  return (
    <Tooltip.Floating
      key={`${item.name}`}
      withinPortal
      multiline
      color="white"
      className="shadow-card w-44 rounded-xl px-2 pb-3 pt-2"
      label={
        <>
          <div className="mb-2 flex justify-between gap-2">
            <p className="text-2xs text-blue-6 mt-1.5 leading-6">{item.name}</p>
            <div>
              <Badge className="bg-blue-6 text-white">{`${item.percentage}%`}</Badge>
            </div>
          </div>
          <p className="text-2xs text-gray-8 leading-7">{item.description}</p>
        </>
      }
    >
      <button
        type="button"
        onClick={() => {
          if (enabledToggleTopic) {
            onToggleTopic(item.name);
          }
        }}
        className={classNames('flex min-h-10 items-center justify-center p-1.5', {
          'cursor-default': !enabledToggleTopic,
          'hover:!bg-blue-9': enabledToggleTopic && !isSelectedTopic,
          '!bg-green-6': isSelectedTopic,
        })}
        style={{
          backgroundColor: getTopicColor(index),
          flexBasis: `${(item.percentage / totalSize) * 100}%`,
        }}
      >
        <span className="text-2xs line-clamp-2 leading-7 text-white">{item.name}</span>
      </button>
    </Tooltip.Floating>
  );
};

const TopicColumn = ({
  column,
  sortedTopics,
  selectedTopic,
  onToggleTopic,
}: {
  column: { name: string; children: ReviewTopic[] };
  sortedTopics: ReviewTopic[];
  selectedTopic?: string;
  onToggleTopic?: (name: string) => void;
}) => {
  const totalSize = column.children.reduce((acc, child) => acc + child.percentage, 0);

  return (
    <div className="flex grow flex-col gap-1">
      {column.children.map((item) => (
        <TopicItem
          key={`${column.name}-${item.name}`}
          item={item}
          totalSize={totalSize}
          index={sortedTopics.findIndex((t) => t.name === item.name)}
          selectedTopic={selectedTopic}
          onToggleTopic={onToggleTopic}
        />
      ))}
    </div>
  );
};
