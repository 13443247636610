import React from 'react';

import { Menu, ActionIcon, Flex } from '@mantine/core';

import { MenuIcon } from '~anyx/shared/icons';
import { MediaQueryType, useScreenBreakpoint } from '~anyx/shared/utils';

interface AsideTitleLayoutProps {
  alwaysVisibleChildren?: React.ReactNode[];
  dropdownChildren?: React.ReactNode[];
}

export const AsideTitleLayout = ({
  alwaysVisibleChildren = [],
  dropdownChildren = [],
}: AsideTitleLayoutProps) => {
  const isMobile = useScreenBreakpoint('sm', MediaQueryType.MAX);

  return (
    <div className="flex">
      {dropdownChildren?.length > 0 && (
        <>
          {isMobile ? (
            <Menu shadow="md">
              <Menu.Target>
                <ActionIcon variant="default" aria-label="Actions" className="mr-2">
                  <MenuIcon />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                {dropdownChildren.map((child, index) => (
                  <Menu.Item key={index} className="w-full" component="div">
                    {child}
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          ) : (
            <Flex className="mr-2 gap-2">
              {dropdownChildren.map((child, index) => (
                <div key={index}>{child}</div>
              ))}
            </Flex>
          )}
        </>
      )}
      <Flex className="gap-2">
        {alwaysVisibleChildren.map((child, index) => (
          <div key={index}>{child}</div>
        ))}
      </Flex>
    </div>
  );
};
